import { useCallback, useEffect } from "react";
import { Button, App, Popconfirm, Select, Space, Upload, Row, Col } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  ImportOutlined,
  RightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ASSET_DESCRIPTIONS, SchemaType, SchemaTypes } from "@properate/common";
import { deleteField } from "firebase/firestore";
import { useUser } from "@properate/auth";
import { ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import axios from "axios";
import { FileUploadResponse } from "@cognite/sdk";
import { archiveSchema, updateSchemaOfType } from "@/services/schemas";
import { ShowEdit } from "@/components/ShowEdit";
import { CompactContent } from "@/components/CompactContent";
import { SchemaTitle } from "@/components/SchemaTitle";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { NextPrevious } from "../NextPrevious/NextPrevious";
import { SchemaKpis } from "../SchemaKpis/SchemaKpis";
import { getSchemaTitle } from "../utils";
import { TechnicalSchema } from "./TechnicalSchema";
import { SENSOR_QUERIES } from "./TechnicalSchema/sensorQueries";
import { StickyPageHeader } from "./elements";

export function getTextFile(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export const processImage = (text: string) => {
  const parseXml = new window.DOMParser().parseFromString(
    text,
    "image/svg+xml",
  );

  const bg = [
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "#182334",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("fill") === "#182334",
    ),
    ...Array.from(parseXml.getElementsByTagName("ellipse")).filter(
      (el) => el.getAttribute("fill") === "#182334",
    ),
    ...Array.from(parseXml.getElementsByTagName("circle")).filter(
      (el) => el.getAttribute("fill") === "#182334",
    ),
  ];

  bg.forEach((el) => el.classList.add("bg"));

  const bg1 = [
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "#161E2D",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("fill") === "#161E2D",
    ),
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "#111928",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("fill") === "#111928",
    ),
  ];

  bg1.forEach((el) => el.classList.add("bg1"));

  const bg2 = [
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "#434E5C",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("fill") === "#434E5C",
    ),
  ];

  bg2.forEach((el) => el.classList.add("bg2"));

  const fg = [
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "white",
    ),
    ...Array.from(parseXml.getElementsByTagName("text")).filter(
      (el) => el.getAttribute("fill") === "white",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("fill") === "white",
    ),
  ];

  fg.forEach((el) => el.classList.add("fg"));

  const fg1 = [
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("fill") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("ellipse")).filter(
      (el) => el.getAttribute("fill") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("circle")).filter(
      (el) => el.getAttribute("fill") === "#DADEE3",
    ),
  ];

  fg1.forEach((el) => el.classList.add("fg1"));

  const stroke = [
    ...Array.from(parseXml.getElementsByTagName("line")).filter(
      (el) => el.getAttribute("stroke") === "#858C94",
    ),
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("stroke") === "#858C94",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("stroke") === "#858C94",
    ),
    ...Array.from(parseXml.getElementsByTagName("ellipse")).filter(
      (el) => el.getAttribute("stroke") === "#858C94",
    ),
  ];

  stroke.forEach((el) => el.classList.add("fgStroke"));

  const stroke1 = [
    ...Array.from(parseXml.getElementsByTagName("line")).filter(
      (el) => el.getAttribute("stroke") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("stroke") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("stroke") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("ellipse")).filter(
      (el) => el.getAttribute("stroke") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("circle")).filter(
      (el) => el.getAttribute("stroke") === "#DADEE3",
    ),
  ];

  stroke1.forEach((el) => el.classList.add("fgStroke1"));

  // settings
  const settings = [
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "#293647",
    ),
  ];

  settings.forEach((el) => el.classList.add("bgSettings"));

  Object.keys(SENSOR_QUERIES).forEach((key) => {
    const dem = Array.from(parseXml.getElementsByTagName("g")).filter((g) =>
      g.id.startsWith(key),
    );
    dem.forEach((el) => {
      el.dataset.type = key;

      const status = Array.from(el.getElementsByTagName("g")).filter((g) =>
        g.id.startsWith("add-"),
      );
      if (status.length > 0) {
        el.classList.add("item");

        let pos = "top";
        if (status[0].id.startsWith("add-right")) {
          pos = "right";
        } else if (status[0].id.startsWith("add-left")) {
          pos = "left";
        } else if (
          status[0].id.startsWith("add-down") ||
          status[0].id.startsWith("add-under")
        ) {
          pos = "bottom";
        }

        el.dataset.position = pos;
        el.classList.add(pos);
      }
      status.forEach((el) => {
        el.classList.add("status");
        if (key.startsWith("set-point-")) {
          el.classList.add("setPoint");
        }
      });
    });
  });

  return parseXml.documentElement;
};

type Props = {
  title: string;
  type: SchemaTypes;
  id: string;
  fileId: string;
  view: string;
  buildings: string[];
  systems: string[];
  availableSensors?: JSX.Element[];
  schema?: SchemaType;
  image?: HTMLElement;
  setImage: (image: HTMLElement) => void;
};

export const SchemaView = ({
  title,
  type,
  id,
  fileId,
  view,
  buildings,
  systems,
  availableSensors,
  schema,
  image,
  setImage,
}: Props) => {
  const { message } = App.useApp();
  const t = useTranslations();
  const { client } = useCogniteClient();
  const currentBuilding = useCurrentBuilding();
  const navigate = useNavigate();
  const user = useUser();
  const setSelectedSystem = useCallback(
    async (val: string | undefined) => {
      await updateSchemaOfType(type, fileId, {
        system: (!val || val === "-1" ? deleteField() : val) as any,
      });
    },
    [type, fileId],
  );

  useEffect(() => {
    if (image && schema?.sensors) {
      const newImage = image.cloneNode(true) as HTMLElement;

      let changed = false;
      Object.keys(schema.sensors).forEach((key) => {
        const item = newImage.querySelector("#" + key) as SVGGraphicsElement;
        if (item && !item.classList.contains("value")) {
          changed = true;
          item.classList.add("value");
        }
      });
      newImage.querySelectorAll(".value").forEach((value) => {
        if (!schema.sensors[value.id]) {
          changed = true;
          value.classList.remove("value");
        }
      });

      if (changed) {
        setImage(newImage);
      }
    }
  }, [image, schema?.sensors, setImage]);

  const archiveFile = async () => {
    await archiveSchema(type, fileId);
    navigate(`/asset/${id}/${type}`);
  };

  return (
    <>
      <StickyPageHeader
        title={
          <Space>
            <Link to={`/asset/${id}/${type}`}>{title}</Link>
            <RightOutlined />
            {!user.isViewer && view === "edit" ? (
              <ShowEdit
                text={schema?.name}
                onFinish={async (text) => {
                  await updateSchemaOfType(type, fileId, {
                    name: text,
                  });
                }}
              />
            ) : (
              <SchemaTitle>{getSchemaTitle(schema)}</SchemaTitle>
            )}
          </Space>
        }
        extra={
          <Space direction="vertical">
            <div className="flex">
              {view === "edit" ? (
                <Space>
                  <Select
                    value={schema?.subBuilding}
                    style={{ width: "100px" }}
                    onSelect={async (val: string) => {
                      await updateSchemaOfType(type, fileId, {
                        subBuilding: val,
                      });
                    }}
                  >
                    {buildings &&
                      buildings.map((building) => (
                        <Select.Option value={building} key={building}>
                          {building}
                        </Select.Option>
                      ))}
                  </Select>
                  <Select
                    value={schema?.system ?? "-1"}
                    style={{ width: "200px" }}
                    onChange={setSelectedSystem}
                  >
                    <Select.Option value="-1">
                      {t("floor-plan.toolbar.select-all-systems-label")}
                    </Select.Option>
                    {systems.map((system) => (
                      <Select.Option value={system} key={system}>
                        {system} {ASSET_DESCRIPTIONS[parseInt(system)]}
                      </Select.Option>
                    ))}
                  </Select>
                  {schema?.cogniteFileId && (
                    <Upload
                      accept="image/*"
                      showUploadList={false}
                      onChange={async (info: any) => {
                        if (info.file.status === "done") {
                          message.destroy();
                          await updateSchemaOfType(type, fileId, {
                            ...schema,
                            cogniteFileId: info.file.response,
                            backgroundImage: "",
                          });
                        } else if (info.file.status === "error") {
                          message.destroy();
                          message.error(
                            `Klarte ikke å laste opp ${info.file.name}.`,
                          );
                        }
                      }}
                      multiple={false}
                      customRequest={async (fileInfo: any) => {
                        const fileName = fileInfo.file.name;
                        message.loading({
                          content: t("floor-plan.toolbar.uploading-schema", {
                            fileName,
                          }),
                          key: fileInfo.file.name,
                        });
                        try {
                          const fileUploadResponse = await client.files.upload({
                            name: fileInfo.file.name,
                            dataSetId: currentBuilding.dataSetId,
                            assetIds: [parseInt(id)],
                            mimeType: fileInfo.file.type,
                            labels: [
                              {
                                externalId: "internal_schema_background",
                              },
                            ],
                          });

                          await axios.put(
                            (fileUploadResponse as FileUploadResponse)
                              .uploadUrl,
                            fileInfo.file,
                          );

                          fileInfo.onSuccess(fileUploadResponse.id);
                        } catch (error: any) {
                          fileInfo.onError(error);
                        }
                      }}
                    >
                      <Button icon={<ImportOutlined />}>
                        {t("floor-plan.toolbar.upload")}
                      </Button>
                    </Upload>
                  )}
                  {!schema?.cogniteFileId && (
                    <Upload
                      showUploadList={false}
                      beforeUpload={(file) => {
                        const isSvg = file.type === "image/svg+xml";
                        if (!isSvg) {
                          message.error(
                            t("floor-plan.toolbar.error-upload-only-svg-files"),
                          );
                        }
                        const isLt900KB = file.size / 1024 < 900;
                        if (!isLt900KB) {
                          message.error(
                            t(
                              "floor-plan.toolbar.error-image-larger-than-900KB",
                            ),
                          );
                        }
                        return isSvg && isLt900KB;
                      }}
                      onChange={async (info: any) => {
                        if (info.file.status === "done") {
                          const fileName = info.file.name;
                          message.success(
                            t("floor-plan.toolbar.file-uploaded-successfully", {
                              fileName,
                            }),
                          );
                        } else if (info.file.status === "error") {
                          const fileName = info.file.name;
                          message.error(
                            t("floor-plan.toolbar.error-uploading-file", {
                              fileName,
                            }),
                          );
                        }
                      }}
                      multiple={false}
                      customRequest={async (fileInfo: any) => {
                        const fileName = fileInfo.file.name;
                        message.loading({
                          content: t("floor-plan.toolbar.uploading-schema", {
                            fileName,
                          }),
                          key: fileInfo.file.name,
                        });

                        const text = await getTextFile(fileInfo.file);
                        await updateSchemaOfType(type, fileId, {
                          image: text,
                        });
                      }}
                    >
                      <Button icon={<UploadOutlined />}>
                        {t("floor-plan.toolbar.upload")}
                      </Button>
                    </Upload>
                  )}
                  <Popconfirm
                    placement="top"
                    title={t("floor-plan.toolbar.popconfirm-delete-label")}
                    onConfirm={archiveFile}
                    okText={t("floor-plan.toolbar.popconfirm-on-ok")}
                    cancelText={t("floor-plan.toolbar.popconfirm-on-cancel")}
                  >
                    <Button
                      type="primary"
                      icon={<DeleteOutlined />}
                      danger
                      style={{
                        marginRight: "0px",
                        backgroundColor: "#db0657",
                      }}
                    >
                      {t("floor-plan.toolbar.delete-button-label")}
                    </Button>
                  </Popconfirm>
                  <Button
                    onClick={() => {
                      navigate(`/asset/${id}/${type}/${fileId}/view`);
                    }}
                  >
                    {t("floor-plan.toolbar.save-button-label")}
                  </Button>
                </Space>
              ) : (
                <Button
                  style={{ marginLeft: "auto" }}
                  disabled={user.isViewer}
                  onClick={() => {
                    navigate(`/asset/${id}/${type}/${fileId}/edit`);
                  }}
                >
                  {t("floor-plan.toolbar.edit-button-label")}
                </Button>
              )}
              <ToggleSidebarButton
                hiddenWhenSidebarVisible
                className="ml-2"
                sidebarHiddenContent={t("notes.show-notes-button")}
              />
            </div>
          </Space>
        }
      >
        <Row justify="space-between">
          <Col>{view === "edit" && <div>{availableSensors}</div>}</Col>
          <Col>
            {schema && (
              <NextPrevious
                buildingId={schema.rootAssetId}
                id={fileId}
                type={type}
              />
            )}
          </Col>
        </Row>
      </StickyPageHeader>
      <CompactContent>
        {schema && (
          <SchemaKpis snapshotId={fileId} type={type} schema={schema} />
        )}
        {schema && image && (
          <TechnicalSchema
            image={image}
            edit={view === "edit"}
            selectedBuilding={schema.subBuilding ?? buildings[0]}
            selectedSystem={schema.system}
            technicalSchema={schema}
            onChangeTechnicalSchema={(data) =>
              updateSchemaOfType(type, fileId, { ...schema, ...data })
            }
            availableSensors={availableSensors}
            type={type}
          />
        )}
      </CompactContent>
    </>
  );
};
