import { browserFirestore } from "@properate/firebase";
import { useEffect, useState } from "react";
import { App, Button, Select, Space, Switch, Upload } from "antd";
import { useTranslations } from "@properate/translations";
import { PageHeader } from "@ant-design/pro-layout";
import { ImportOutlined, UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Step } from "react-joyride";
import { addDoc, collection } from "firebase/firestore";
import { SchemaType, SchemaTypes } from "@properate/common";
import { useUser } from "@properate/auth";
import axios from "axios";
import { FileUploadResponse } from "@cognite/sdk";
import {
  addSchemaOfType,
  updateSchemaOfType,
  useSchemaByRootAssetId,
} from "@/services/schemas";
import { SchemaCompactViewTable } from "@/components/TechnicalSchema/SchemaCompactViewTable";
import { SchemaExpandViewTable } from "@/components/TechnicalSchema/SchemaExpandViewTable";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import { useWindowSize } from "@/hooks/useWindowSize";
import { CompactContent } from "@/components/CompactContent";
import { useHelp } from "@/context/HelpContext";
import { useOnboarding } from "@/utils/onboarding";
import { JoyrideWrapper } from "@/components/JoyrideWrapper/JoyrideWrapper";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { Error } from "@/pages/Error/Error";
import { SearchInput } from "@/components/SearchIndex";
import { SelectKpisModal } from "../SchemaKpis/SelectKpisModal";
import { getTextFile } from "../SchemaView/SchemaView";
import { arrayBufferToBase64 } from "./utils";

const ESTIMATED_TABLE_HEADER_HEIGHT = 45;

type Props = {
  id: string;
  type: SchemaTypes;
  title: string;
  availableBuildings?: string[];
  joyrideSteps: Step[];
  help: { title: string; content: string };
  showProperateButton?: boolean;
};

export const SchemaList = ({
  id,
  type,
  availableBuildings,
  joyrideSteps,
  help,
  showProperateButton,
  title,
}: Props) => {
  const { client } = useCogniteClient();
  const t = useTranslations();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const user = useUser();
  const [showSelectKpisModal, setShowSelectKpisModal] = useState();
  const { setHelp } = useHelp();
  const [onboardingSteps, setOnboardingStepCompleted] =
    useOnboarding(joyrideSteps);
  const [selectedBuilding, setSelectedBuilding] = useState<string>("all");
  const [search, setSearch] = useState("");

  const {
    data,
    error: hasErrorSchemaByRootAssetId,
    isLoading: isLoadingSchemaByRootAssetId,
  } = useSchemaByRootAssetId(type, Number(id));
  const {
    data: userSettings,
    error: hasErrorUserSettings,
    isLoading: isLoadingUserSettings,
  } = useUserSettings();

  const showPreviewSchemaValue =
    userSettings?.buildings?.[id]?.showPreviewSchema;
  const { height: windowHeight } = useWindowSize();

  const tableHeight =
    windowHeight - PAGE_LAYOUT_HEIGHT - ESTIMATED_TABLE_HEADER_HEIGHT;

  useEffect(() => {
    setHelp({
      title: help.title,
      content: (
        <>
          <p>{help.content}</p>
        </>
      ),
    });
  }, [setHelp, help.title, help.content]);

  const showSchemaCompact = async (showPreviewSchema: boolean) => {
    await mutateUserSettings({
      buildings: {
        [id]: {
          showPreviewSchema,
        },
      },
    });
  };

  async function handleChangeSelectedSensors(
    snapshotId: string,
    selectedSensors: any,
  ) {
    await updateSchemaOfType(type, snapshotId, {
      selectedSensors,
    });
  }

  const currentBuilding = useCurrentBuilding();

  if (hasErrorUserSettings || hasErrorSchemaByRootAssetId) {
    return <Error />;
  }

  return (
    <SpinnerWithDelay
      isLoading={isLoadingUserSettings || isLoadingSchemaByRootAssetId}
    >
      <PageHeader
        title={title}
        extra={
          <Space>
            <Switch
              data-testid="show-preview-schema-switch"
              onChange={showSchemaCompact}
              checkedChildren={t("floor-plan.toolbar.preview-schema")}
              unCheckedChildren={t("floor-plan.toolbar.preview-schema")}
              checked={showPreviewSchemaValue}
            />
            <Select
              value={selectedBuilding}
              style={{ width: "100px" }}
              placeholder={t("floor-plan.toolbar.select-building-label")}
              onSelect={(val: string) => {
                setSelectedBuilding(val);
              }}
              aria-label={t("floor-plan.toolbar.select-building-label")}
            >
              <Select.Option value={"all"} key={"all"}>
                {t("calculation-flow.show-all")}
              </Select.Option>
              {availableBuildings &&
                availableBuildings.map((building) => (
                  <Select.Option value={building} key={building}>
                    {building}
                  </Select.Option>
                ))}
            </Select>
            <SearchInput
              style={{ width: 200 }}
              allowClear
              placeholder={t("floor-plan.search")}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
            <Upload
              disabled={user.isViewer}
              accept="image/*"
              showUploadList={false}
              onChange={async (info: any) => {
                if (info.file.status === "done") {
                  message.destroy();

                  const src = await client.documents.preview
                    .documentAsImage(info.file.response, 1)
                    .then((response) => {
                      return `data:text/plain;base64,${arrayBufferToBase64(
                        response,
                      )}`;
                    })
                    .catch((error) => {
                      console.debug("preview not found", error);
                      return undefined;
                    });

                  if (!src) {
                    message.destroy();
                    const fileName = info.file.name;
                    message.error(
                      t("floor-plan.toolbar.error-uploading-schema", {
                        fileName,
                      }),
                    );
                    return;
                  }

                  const [width, height] = await new Promise<[number, number]>(
                    (resolve, reject) => {
                      const img = new Image();
                      img.src = src;
                      img.onload = function () {
                        resolve([img.width, img.height]);
                      };
                      img.onerror = function () {
                        reject(t("floor-plan.toolbar.error-loading-image"));
                      };
                    },
                  );
                  const result = await addDoc(
                    collection(browserFirestore, type),
                    {
                      assetId: parseInt(id),
                      rootAssetId: parseInt(id),
                      image: `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>          `,
                      cogniteFileId: info.file.response,
                      name: title,
                      sensors: {},
                    },
                  );
                  navigate(`/asset/${id}/${type}/${result.id}/edit`);
                } else if (info.file.status === "error") {
                  message.destroy();
                  const fileName = info.file.name;
                  message.error(
                    t("floor-plan.toolbar.error-uploading-schema", {
                      fileName,
                    }),
                  );
                }
              }}
              multiple={false}
              customRequest={async (fileInfo: any) => {
                const fileName = fileInfo.file.name;
                message.loading({
                  content: t("floor-plan.toolbar.uploading-schema", {
                    fileName,
                  }),
                  key: fileInfo.file.name,
                });

                try {
                  const fileUploadResponse = await client.files.upload({
                    name: fileInfo.file.name,
                    dataSetId: currentBuilding.dataSetId,
                    assetIds: [parseInt(id)],
                    mimeType: fileInfo.file.type,
                    labels: [
                      {
                        externalId: "internal_schema_background",
                      },
                    ],
                  });

                  await axios.put(
                    (fileUploadResponse as FileUploadResponse).uploadUrl,
                    fileInfo.file,
                  );

                  fileInfo.onSuccess(fileUploadResponse.id);
                } catch (error: any) {
                  fileInfo.onError(error);
                }
              }}
            >
              <Button icon={<ImportOutlined />} disabled={user.isViewer}>
                {t("floor-plan.toolbar.import-image-label")}
              </Button>
            </Upload>
            {showProperateButton && (
              <Upload
                disabled={user.isViewer}
                showUploadList={false}
                beforeUpload={(file) => {
                  const isSvg = file.type === "image/svg+xml";
                  if (!isSvg) {
                    t("floor-plan.toolbar.error-upload-only-svg-files");
                  }
                  const isLt900KB = file.size / 1024 < 900;
                  if (!isLt900KB) {
                    t("floor-plan.toolbar.error-image-larger-than-900KB");
                  }
                  return isSvg && isLt900KB;
                }}
                onChange={async (info: any) => {
                  if (info.file.status === "done") {
                    message.destroy();
                  } else if (info.file.status === "error") {
                    message.destroy();
                    const fileName = info.file.name;
                    message.error(
                      t("floor-plan.toolbar.error-uploading-file", {
                        fileName,
                      }),
                    );
                  }
                }}
                multiple={false}
                customRequest={async (fileInfo: any) => {
                  const fileName = fileInfo.file.name;
                  message.loading({
                    content: t("floor-plan.toolbar.uploading-schema", {
                      fileName,
                    }),
                    key: fileInfo.file.name,
                  });
                  const text = await getTextFile(fileInfo.file);
                  const file = await addSchemaOfType(type, {
                    rootAssetId: parseInt(id),
                    image: text,
                    name: title,
                    sensors: {},
                  });
                  navigate(`/asset/${id}/${type}/${file.snapshotId}/edit`);
                }}
              >
                <Button icon={<UploadOutlined />} disabled={user.isViewer}>
                  {t("floor-plan.toolbar.add-properate-design")}
                </Button>
              </Upload>
            )}
          </Space>
        }
      />
      <CompactContent>
        {showPreviewSchemaValue ? (
          <SchemaExpandViewTable
            data={data}
            type={type}
            setShowSelectKpisModal={setShowSelectKpisModal}
            selectedBuilding={selectedBuilding}
            search={search}
            title={title}
            onChangeSelectedSensors={handleChangeSelectedSensors}
            height={tableHeight}
          />
        ) : (
          <SchemaCompactViewTable
            data={data}
            type={type}
            setShowSelectKpisModal={setShowSelectKpisModal}
            selectedBuilding={selectedBuilding}
            search={search}
            onChangeSelectedSensors={handleChangeSelectedSensors}
            height={tableHeight}
          />
        )}
        {showSelectKpisModal && (
          <SelectKpisModal
            onHide={() => {
              setShowSelectKpisModal(undefined);
            }}
            schema={
              data.find(
                (schema) => schema.snapshotId! === showSelectKpisModal,
              ) as Pick<SchemaType, "selectedSensors" | "sensors">
            }
            schemaId={showSelectKpisModal}
            schemaType={type}
          />
        )}
        {onboardingSteps && onboardingSteps.length > 0 && (
          <JoyrideWrapper
            content={onboardingSteps[0]}
            onClose={setOnboardingStepCompleted}
          />
        )}
      </CompactContent>
    </SpinnerWithDelay>
  );
};
